import React, { useEffect, useState } from "react";


export const GospelSummaries = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [summaries, setSummaries] = useState([]);

    useEffect(() => {
        const url = "https://axifcsi3bi.execute-api.us-west-2.amazonaws.com/Prod/summaries"
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "X-Api-Key": "rqkaIOVd8y6jmjdA3oV93lcwXU9Obto1PKeTT7Q7",
                "Content-Type": "application/json"
            }),
            mode: "cors"
        })
        .then((response) => response.json())
        .then((json) => setSummaries(json["summaries"]))
        .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        if (summaries.length !== 0) {
            setIsLoading(false);
        }
    }, [ summaries ]);

    return (
        <div>
            <h2>Gospel and Thought for the Day</h2>
            <div>
                { isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        summaries.map((summary) => {
                            return (
                                <div key={ summary.id } className="my-5 text-start">
                                    <h3>{ (new Date(summary.timestamp)).toDateString() }</h3>
                                    <p className="text-muted">{ summary.title }</p>
                                    <p>{ summary.text}</p>
                                    <a href={ summary.src } target="_blank" rel="noreferrer">{ summary.src }</a>
                                </div>
                            )
                        })
                    )
                }
            </div>
        </div>
    );
}