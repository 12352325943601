// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import './App.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VirginMary from './virgen_de_guadalupe.jpeg';
// import { LatinInterlinear } from './LatinInterlinear/LatinInterlinear';
import { GospelSummaries } from './GospelSummaries/GospelSummaries';

export const App = () => {
  const { t } = useTranslation();
  return (
    <div className="App d-flex flex-column min-vh-100">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand" href="#!">{t("Mystical Rose")}</a>
        </div>
      </nav>
      <div className="container mt-5">
          <div className="row">
              <div className="col-lg-8">
                <GospelSummaries />
                { /* <LatinInterlinear /> */ }
              </div>
              <div className="col-lg-4">
                  <div className="card mb-4">
                      <div className="card-header">{t("About Us")}</div>
                      <div className="card-body">{t("This work is dedicated to Our Lady, the Mystical Rose, the holy Mother of God.  To contact us, please email ")}<a href="mailto: contact@mysticalrose.net">contact@mysticalrose.net</a></div>
                  </div>
                  <div className="side-image">
                      <img className="img-fluid" alt="Icon of the blessed Virgin Mary by Luke the Evangelist" src={ VirginMary }></img>
                  </div>
              </div>
          </div>
      </div>
      <footer className="py-5 bg-dark mt-auto">
          <div className="container">
            <p className="m-0 text-center text-white">
              <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">
                <img alt="Creative Commons License" style={{ borderWidth: 0 }} src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png" />
              </a>
              <br />
              {t("This work is licensed under a")} <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">{t("Creative Commons Attribution-ShareAlike 4.0 International License")}</a>.
            </p>
          </div>
      </footer>
    </div>
  );
}

export default App;
